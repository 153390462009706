import React from "react";

//import PropTypes from 'prop-types'
import Home from "./_index.js";

import Helmet from "react-helmet";
import TrackingTags from "../components/tracking_tags/index.js";

const config = require("../../gatsby-config");

const Index = () => (
  <>
    <Helmet
      title="Havada Kalmasın"
      meta={[
        {
          name: "description",
          content: `Hava kirliliğine karşı hemen harekete geç.`,
        },
        {
          property: "og:url",
          content: `${config.siteMetadata.siteUrl}`,
        },
        { property: "og:title", content: `Havada Kalmasın - Greenpeace` },
        {
          property: "og:description",
          content: `Hava kirliliği nedeniyle dünyada her yıl 8 milyon insan erken ölüyor. Sen de hava kirliliğine karşı harekete geç, yaşadığın şehirde hava kirliliği ne durumda öğren. #HavadaKalmasın`,
        },
        { property: "og:site_name", content: `Havada Kalmasın` },
        { property: "og:type", content: `Website` },
        { property: "og:image:width", content: `1200` },
        { property: "og:image:height", content: `630` },
        {
          property: "og:image",
          content: `/social-assets/jenerik.jpg`,
        },
        { property: "twitter:card", content: `summary_large_image` },
        {
          property: "twitter:domain",
          content: `${config.siteMetadata.siteUrl}`,
        },
        {
          property: "twitter:url",
          content: `${config.siteMetadata.siteUrl}`,
        },
        { property: "twitter:site", content: `@Greenpeace_Med` },
        {
          property: "twitter:title",
          content: `Havada Kalmasın - Greenpeace`,
        },
        {
          property: "twitter:description",
          content: `Hava kirliliği nedeniyle dünyada her yıl 8 milyon insan erken ölüyor. Sen de hava kirliliğine karşı harekete geç, yaşadığın şehirde hava kirliliği ne durumda öğren. #HavadaKalmasın`,
        },
        {
          property: "twitter:image",
          content: `/social-assets/jenerik.jpg`,
        },
      ]}
    >
      <html lang="tr" />
      <TrackingTags />
    </Helmet>
    <Home></Home>
  </>
);

export default Index;
